import React from 'react';
import Tag from '../Tag';

import * as Styled from './Headline.styles';

type Props = {
  children: any;
  level: string;
  id?: string;
};

const levels = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
};

const Headline = ({ children, level, ...props }: Props) => {
  return (
    <Tag
      tag={levels[level]}
      displayName="Headline"
      attributes={{ level }}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default Headline;
