import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/seo';
import Component from '../components/Component';
import Headline from '../components/Headline';
import Import from '../components/Import';
import Tag from '../components/Tag';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div style={{ marginBottom: '2rem' }}>
      <Import importString="React" path="react" />
      <Import importString="Headline" path="../components/Headline" />
    </div>

    <Component name="404">
      <Headline level="1">404: Not Found</Headline>

      <Tag tag="p">
        Ups.. diese Seite scheint es nicht zu geben. Probier es doch mal mit der{' '}
        <Tag tag="span" displayName="a" isInline attributes={{ href: '/' }}>
          <Link to="/">Startseite</Link>
        </Tag>
        .
      </Tag>
    </Component>
  </>
);

export default NotFoundPage;
